import React, { useState, useEffect } from 'react';
import Preloader from "../Preloader/Preloader.jsx";

const LoadData = ({ onDataLoaded, isOpen }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.jsx.evalScript(
      "@JSXBIN@ES@2.0@MyBbyBnABMAbyBn0AJJBnASzKjBjVjUjIjPjSiOjBjNjFBAneJiIjBjSjDjIjFjOjLjPftJCnASzLjQjSjPjKjFjDjUiOjBjNjFCBneNiBiFhShAiFjYjUjFjOjTjJjPjOftJEnASzMjTjDjSjJjQjUiGjPjMjEjFjSDCEjzGiGjPjMjEjFjSEfRBCzBhLFCFXzGjGjTiOjBjNjFGfXzIjVjTjFjSiEjBjUjBHfjEfnneBhPVBfAnnftnftOFJFnAEXzGjDjSjFjBjUjFIfVDfCnfAhzBhBJVDfCnJHnASzLjGjJjMjFjTiGjPjMjEjFjSKDEjEfRBCFCFXGfVDfCnneBhPVCfBnnftnftOIJInAEXIfVKfDnfAVDfCnJKnASzOjFjYjUjFjOjTjUjJjPjOiEjBjUjBLEVzGjTjUjSjJjOjHMfInftcMnAVzIjTjPjGjUjXjBjSjFNfJERBFeCjBjFfRBFeCjQjQfRBFeCjBjJfRBFeCjQjTfEbOn0ACJOnASNJneHjBjFhSjEjBjUjBffDPnAzAOtbSn0ACJSnASNJneGjQjQiEjBjUjBffDTnAOtbWn0ACJWnASNJneGjBjJiEjBjUjBffDXnAOtbgan0ACJganASNJneGjQjTiEjBjUjBffDgbnAOtOgebgfn0ACJgfnASzOjFjYjUjFjOjTjUjJjPjOiGjJjMjFPFEjzEiGjJjMjFQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftOhDbhEn0AEJhEnAEXzEjPjQjFjORfVPfFRDFeBjSFeEiUiFiYiUFeEhfhfhfhfffJhFnASzKjHjFjUiDjPjOjUjFjOjUSGEXzEjSjFjBjETfVPfFnfnftJhGnAEXzFjDjMjPjTjFUfVPfFnfOhInACzDhdhdhdVVSfGVLfEnnbhKn0AFJhKnASzLjDjSjFjBjUjFjEiGjJjMjFWHEjQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftJhQnABXzIjFjOjDjPjEjJjOjHXfVWfHneFiViUiGhNhYfJhRnAEXRfVWfHRBFeBjXffJhSnAEXzFjXjSjJjUjFYfVWfHRBVLfEffJhTnAEXUfVWfHnfAXzGjFjYjJjTjUjTZfVPfFbhWn0AFJhWnASWHEjQfRBCFCFCFXGfXHfjEfnneZhPiIjBjSjDjIjFjOjLjPhPiBiFhShAiFjYjUjFjOjTjJjPjOhPVNfJnnnneEhOjKjTjYftnftJhZnABXXfVWfHneFiViUiGhNhYfJhanAEXRfVWfHRBFeBjXffJhbnAEXYfVWfHRBVLfEffJhcnAEXUfVWfHnfACzChBhdgaVNfJjzJjVjOjEjFjGjJjOjFjEgbfnnnAKN4B0AhAB40BiAC4B0AiAD4C0AiAK4D0AiAL4E0AiAP4F0AiAM40BhAS4G0AiAW4H0AiACIAzHjHjFjUiEjBjUjBgcAhf0EOByB"
    );
      const getExtension = async () => {
          const versionUpdate = new Date().getTime();
          try {
              const response = await fetch(`./scripts/aiData.js?ver=${versionUpdate}`);
              const fetchedData = await response.text();
              window.jsx.evalScript(`getData(${JSON.stringify(fetchedData)}, "ai")`);
              onDataLoaded();
          } catch (error) {
              console.error("Ошибка при получении данных:", error);
          }
          setLoading(false);
      };

      getExtension();
  }, [onDataLoaded]);

  // Если мы не находимся в режиме production, возвращаем null
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return loading ? <Preloader isOpen={isOpen}/> : null;
};

export default LoadData;
