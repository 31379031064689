import { BASE_URL } from "./constants.js";

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
    this._headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
  }

  _fetchWithCheck(endpoint, config = {}) {
    return fetch(`${this._baseUrl}/${endpoint}`, {
      ...config,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("jwt")}`,
        ...config.headers,
      },
    }).then((response) => {
      return response.json().then((data) => {
        if (!response.ok) {
          throw new Error(data.message);
        }
        return data;
      });
    });
  }

  createUser({ name, family, login, email, password }) {
    return this._fetchWithCheck('signup', {
      method: 'POST',
      body: JSON.stringify({ name, family, login, email, password }),
    });
  }

  login({ email, password }) {
    return this._fetchWithCheck("signin", {
      method: "POST",
      body: JSON.stringify({ email, password }),
    });
  }

  getUserInfo() {
    return this._fetchWithCheck("users/me");
  }

  updateSubscription(userId, data) {
    return this._fetchWithCheck(`users/${userId}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  getButtons() {
    return this._fetchWithCheck("aibuttons");
  }
}

const mainApi = new Api({
  baseUrl: BASE_URL,
});

export default mainApi;
