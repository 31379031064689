import React, { useContext, useState } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import { useDrag, useDrop } from "react-dnd";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./ButtonCard.css";

import ResolutionTools from "../ResolutionTools/ResolutionTools.jsx";
import ImportElementsPremiere from "../ImportElementsPremiere/ImportElementsPremiere.jsx";
import MarkerGroup from "../MarkerGroup/MarkerGroup.jsx";
import RenderGroup from "../RenderGroup/RenderGroup.jsx";
import TransformPreCompGroup from "../TransformPreCompGroup/TransformPreCompGroup.jsx";
import RenameGroup from "../RenameGroup/RenameGroup.jsx";
import NewTemplateGroup from "../NewTemplateGroup/NewTemplateGroup.jsx";
import VideoBolt from "../VideoBolt/VideoBolt.jsx";

const DND_ITEM_TYPE = "BUTTON";

const ButtonCard = ({ button, moveButton, findButton, selectedTag }) => {
  const { category, name, script, argument, icon } = button;
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [buttonTag, setButtonTag] = useVisible(`button-tag-${button._id}`);

  const tagColors = ["#EB3A2D", "#AFEB2D", "#2D8CEB"];

  function generateButtonTags() {
    return (
      <div className="button-card__tags">
        {tagColors.map((color) => (
          <div
            key={color}
            className={`button-tag tag ${buttonTag === color ? "active" : ""}`}
            onClick={(event) => toggleButtonTag(color, event)}
            style={{ backgroundColor: color }}
          ></div>
        ))}
      </div>
    );
  }

  const toggleButtonTag = (color, event) => {
    event.stopPropagation();
    if (buttonTag === color) {
      setButtonTag(null);
    } else {
      setButtonTag(color);
    }
  };

  const handlePopupStateChange = (state) => {
    setPopupIsVisible(state);
  };
  const isDragDisabled = popupIsVisible;
  const currentUser = useContext(CurrentUserContext);

  const originalIndex = findButton(button._id).index;

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: { _id: button._id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { _id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveButton(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover({ _id: draggedId }) {
      if (draggedId !== button._id) {
        const { index: overIndex } = findButton(button._id);
        moveButton(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;

  function handleClick() {
    if (script !== "") {
      window.jsx.evalFile("./Harchenko/AE2 Extension/aiData.jsx");
      window.jsx.evalScript(
        `run(${script}(${JSON.stringify(argument)}));`,
        function (result) {
          // Logic after executing the script
        }
      );
    }
  }

  // if (name.toLowerCase() === "resolution group") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <ResolutionTools
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "import elements group") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <ImportElementsPremiere
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "markers") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <MarkerGroup
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }


  // if (name.toLowerCase() === "render") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <RenderGroup
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "transform pre-compose") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <TransformPreCompGroup
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "rename group") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <RenameGroup
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "new template group") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <NewTemplateGroup
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //       />
  //     </li>
  //   );
  // }

  // if (name.toLowerCase() === "videobolt group") {
  //   return (
  //     <li
  //       className="button-group"
  //       ref={(node) => drag(drop(node))}
  //       style={{ opacity }}
  //     >
  //       <VideoBolt
  //         selectedTag={selectedTag}
  //         buttonTag={buttonTag}
  //         generateButtonTags={generateButtonTags}
  //         closeComps={closeComps}
  //       />
  //     </li>
  //   );
  // }

  const isButtonDisabled =
    currentUser.subscription && currentUser.subscription[category] === false;

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <li
      className={`button-card ${
        buttonTag === "#EB3A2D"
          ? "hover-red"
          : buttonTag === "#AFEB2D"
          ? "hover-green"
          : buttonTag === "#2D8CEB"
          ? "hover-blue"
          : ""
      } ${isButtonDisabled ? "button-card--disabled" : ""}`}
      onClick={isButtonDisabled ? null : handleClick}
      ref={(node) => drag(drop(node))}
      style={isButtonDisabled ? {} : { opacity }}
    >
      <div className="button-card__icon">
        <div dangerouslySetInnerHTML={{ __html: icon }} />
      </div>
      <div className="button-card__title">{name}</div>
      {generateButtonTags()}
    </li>
  );
};

export default ButtonCard;
